import { db } from '@/firebase/config'
import firebase from "firebase/app";
import "firebase/firestore";

export const addMyWish = async (userId, companyId, wishId, status) => {
  try {
    const now = new firebase.firestore.Timestamp.now().toDate();
    
    await db.collection("Profiles").doc(userId).collection("myWishes").add({
      companyId: companyId,
      wishId: wishId,
      status: status,
      time: now
    })
  } catch (error) {
    alert("addMyWish" + error)
  }
};

export default addMyWish