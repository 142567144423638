import { db } from '@/firebase/config'

export const cutMyWishes = async (userId, wishId) => {
	try {
		await db.collection("Profiles").doc(userId).collection("myWishes").where("wishId", "==", wishId).get().then(docs => {
			let promises = []
			docs.forEach(doc => {
				promises.push(doc.ref.delete())
			})
			return Promise.all(promises)
		})
	} catch (error) {
		alert("cutMyWishes: " + error);
	}
}

export default cutMyWishes