import { db, storage } from '@/firebase/config'

const deleteWishResume = async (userId, wishId) => {
  try {
    const storageRef = storage.ref('user_files/' + userId + '/Wish-Resumes/' + wishId);

    const deleteFile = (pathToFile, fileName) => {
      const ref = storage.ref(pathToFile);
      return ref.child(fileName).delete();
    }

    await db.collection("Wish-Resumes").where("userId", "==", userId).where("wishId", "==", wishId).get().then(async docs => {
      let promises = [];
      docs.forEach(doc => {
        promises.push(doc.ref.delete());
      });
      return Promise.all(promises);
    });

    await storageRef.listAll().then(dir => {
      let promises = [];
      dir.items.forEach(fileRef => {
        promises.push(deleteFile(storageRef.fullPath, fileRef.name));
      });
      return Promise.all(promises);
    });

    // console.log("deleteWishResume activated")
  } catch (error) {
    alert("deleteWishResume: " + error);
  }
};

export default deleteWishResume
