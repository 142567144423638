import { db, storage, timestamp } from '@/firebase/config'

const uploadWishResume = async (file, userId, wishId, urlStore) => {
  try {
    const storageRef = storage.ref('user_files/' + userId + '/Wish-Resumes/' + wishId + '/' + file.name);
    let url;

    await storageRef.put(file).then(async () => {
      url = await storageRef.getDownloadURL();
      urlStore.value = url;
      const uploadedAt = timestamp();
      await db.collection("Wish-Resumes").add({ url, filename: file.name, uploadedAt, userId, wishId });
    })

    // console.log("uploadWishResume activated")
  } catch (error) {
    alert("uploadWishResume: " + error)
  }
};

export default uploadWishResume
