import { db } from '@/firebase/config'

const getUrlAndName = async (urlStore, nameStore, userId, type) => {
  try {
    let counter = 0;
    await db.collection(type).where("userId", "==", userId).get().then(snap => {
      snap.forEach(doc => {
        urlStore.value = doc.data().url;
        if (nameStore && doc.data().filename){
          nameStore.value = doc.data().filename;
        }
        counter++;
      });
    });
    if (counter == 0) {
      urlStore.value = null;
      if (nameStore){
        nameStore.value = null;
      }
    }
    // console.log("getUrlAndName activated")
  } catch (error) {
    alert("getUrlAndName: " + error)
  }
};

export default getUrlAndName
